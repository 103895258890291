import { navigate } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Button from "../../components/Button";
import Layout from "../../components/layout/Layout";
import UpdateImage from "../../components/images/Update";
import BusinessSystemsImage from "../../components/images/BusinessSystems";
import MobileAppImage from "../../components/images/MobileApp";
import PageHeader from "../../components/PageHeader";

interface SectionProps {
    header: string;
    image: React.ReactNode;
}

const Section = (props: React.PropsWithChildren<SectionProps>) => {
    const { children, header, image } = props;

    return (
        <section className="mt-5 p-5 rounded-lg border border-solid border-gray shadow" style={{boxShadow: "0 10px 28px rgba(0,0,0,.08)"}}>
            <div className="flex flex-col md:flex-row">
                <div className="md:w-2/3 order-2 md:order-1">
                    <h3 className="text-xl text-primary-default">{header}</h3>
                    {children}
                </div>
                <div className="w-1/2 md:w-1/3 order-1 md:order-2 mx-auto">
                    {image}
                </div>
            </div>
        </section>
    );
};

const Index = () => {
    return (
        <Layout>
            <Helmet>
                <title>Services</title>
                <meta name="description" content="Services provided by Eborsoft."></meta>
            </Helmet>
            <PageHeader title="Services">
                Eborsoft offer a variety of software development services. Below are some of the most popular.
            </PageHeader>
            <section className="">
                <div className="container mx-auto px-8">
                    <Section image={<UpdateImage />} header="Legacy System Re-engineering">
                        <p className="mt-2">
                            Technology evolves continually and it is not uncommon for systems to age
                            and quickly become unsupported, or become "maintenance-only". Internally developed
                            applications and systems are particularly vulnerable to this as your developers
                            move to the next new project. This can result in increased technical debt and costs
                            as systems become more difficult to maintain and secure.
                        </p>

                        <h4 className="font-bold mt-5 mb-2">Eborsoft can work with your business to:</h4>
                        <ul className="list-disc list-outside ml-10">
                            <li>
                                Perform black-box reverse engineering of existing systems where
                                artefacts such as source code have been lost.
                            </li>
                            <li>Re-engineer internal systems, whilst suggesting possible updates.</li>
                            <li>Ensure that re-engineered applications are built on long-lasting technologies.</li>
                            <li>Provide the appropriate amount of documentation, with hand over to any internal development team.</li>
                            <li>Provide as much or as little long-term maintenance as you require.</li>
                        </ul>

                        <Button className="m-5" onClick={() => navigate("/services/legacy-reengineering")}>More details</Button>
                    </Section>
                    <Section image={<BusinessSystemsImage />} header="Business Software Solutions">
                        <p className="mt-2">
                            Good software solutions are essential to any business. Whilst there are a fantastic range of off-the-shelf systems available, 
                            in many cases these aren't quite enough. Eborsoft specializes in the design and development of robust, scalable
                            software solutions for business. Whether the starting point is simply an informal discussion to understand the possibilities,
                            or even if you already have a good idea of your requirements already, Eborsoft can provide the expertise to turn
                            those plans into quality, fit for purpose solutions.
                        </p>

                        <h4 className="font-bold mt-5 mb-2">Bespoke solution highlights:</h4>
                        <ul className="list-disc list-outside ml-10">
                            <li>We will work with you to build a clear and agreed specification. Timings and costs are presented and agreed upfront.</li>
                            <li>Solutions are built using modern and well supported software technologies.</li>
                            <li>Everything is built to be cost effective to maintain, either by Eborsoft or a third party.</li>
                            <li>Flexible hosting - on premise or in the Cloud (where applicable).</li>
                            <li>Standards based security model, including OAUTH2 and SSO implementations.</li>
                            <li>Multiple types of solutions available (desktop applications, web based apps, etc.)</li>
                            <li>Ownership of final product including source code is yours.</li>
                        </ul>

                        <Button className="m-5" onClick={() => navigate("/services/business-systems")}>More details</Button>
                    </Section>
                    <Section image={
                        <MobileAppImage className="rounded-lg" />
                        } header="Mobile Apps">
                        <p className="mt-2">
                            A lot of modern systems go beyond the desktop and server. Staff and customers increasingly expect the systems they
                            use to be "always available" and often this means making such systems available in the form of a mobile-enabled website
                            or a mobile app.
                        </p>
                        <h4 className="font-bold mt-5 mb-2">Features of Eborsoft mobile app solutions:</h4>
                        <ul className="list-disc list-outside ml-10">
                            <li>All apps designed to work on the latest iOS and Android platforms on all mainstream devices.</li>
                            <li>Security first design, ensuring that even compromised devices don't compromise your data.</li>
                            <li>Cost effective implementation - cost sharing between platforms means feature parity and less time to completion.</li>
                            <li>Best practice UI design.</li>
                            <li>Where appropriate Eborsoft can either assist or lead publishing your app via an app store or your MDM.</li>
                        </ul>
                        <Button className="m-5" onClick={() => navigate("/services/mobile-apps")}>More details</Button>
                    </Section>
                </div>
            </section>
        </Layout>
    );
};

export default Index;